import React from "react";
import Layout from "../components/layout";
import Form from "../molecules/ApplyNowForm/Form";
import InsightsHeader from "../molecules/InsightsPage/insightsHeader";
const applyforjob = () => {
  return (
    /* Candidate Information Form 
        Apply here for Goken opportunities. 
        Fill the details here. */
    <Layout isNavWhite={true} hideMap hideFAQ>
      <InsightsHeader
        heading="Candidate Information Form"
        bgImageClass="bg-image-insights-page"
        paraOne="Apply here for Goken Opportunities"
      ></InsightsHeader>
      <Form />
    </Layout>
  );
};
export default applyforjob;
