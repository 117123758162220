import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLanguage } from "../../languageContext";

const Form = () => {
  const { register, handleSubmit } = useForm();
  const currentLanguage = useLanguage();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const onSubmit = (data) => {
    try {
      setLoading(true);
      setMessage("");
      setError("");
      const { firstName, lastName, email, about, jobTitle, attachment } = data;
      console.log(attachment);
      let server = "https://contactus.goken-global.com";
      // let server = "http://127.0.0.1:8002";
      let url = "/webapp/careers";
      let final_url = server + url;

      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };

      const params2 = new FormData();
      params2.append("firstName", firstName);
      params2.append("lastName", lastName);
      params2.append("email", email);
      params2.append("jobTitle", jobTitle);

      params2.append("attachment", attachment[0]);
      params2.append("about", about);
      const configs = {
        headers: {
          "Content-Type": "multipart/form-data",
          // Accept: "application/json",
          // "Access-Control-Allow-Origin": "*",
        },
      };
      console.log("Sending Request...", final_url, params2, config);
      // (async () => {
      //   await fetch(final_url, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //     body: params2,
      //   })
      //     .then((response) => {
      //       console.log(response);
      //       if (response.status > 300) {
      //         setLoading(false);
      //         console.log("error");
      //       }
      //       setLoading(false);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setLoading(false);
      //     });
      // })();
      // let response = fetch(final_url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Accept: "*/*",
      //   },
      //   body: params2,
      // });
      // .then((response) => {
      //   console.log(response.data.message);
      //   setMessage(response.data.message);
      //   setLoading(false);
      // })
      // .catch((error) => {
      //   console.log("Fetch error is ", error);
      //   setError("Something Went Wrong. Please try again after sometime.");
      //   setLoading(false);
      // });
      // console.log(response);
      axios
        .post(final_url, params2, config)
        .then((response) => {
          console.log(response.data.message);
          setMessage(response.data.message);
          setLoading(false);
        })
        .catch((Error) => {
          console.log("Error is ", Error, "Message is", Error.message);
          if (Error.message === "Network Error") {
            console.log("Network Error Captured");
            // setMessage(response.data.message);
            setMessage(
              "Thank you for applying. Our Talent Acquisition Team will get in touch with you within 48 hours."
            );

            setLoading(false);
          } else {
            setError("Something Went Wrong. Please try again after sometime.");
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  try {
    return (
      <div className="careers-apply-form">
        {message.length > 0 ? (
          <p>{message}</p>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="heading">Apply Here</p>
            <div className="col-1">
              <input
                name="firstName"
                placeholder="Your First Name*"
                {...register("firstName", {
                  required: true,
                  maxLength: 20,
                })}
              />
              <input
                className="ml-5px"
                name="lastName"
                placeholder="Your Last Name*"
                {...register("lastName", {
                  required: true,
                  pattern: /^[A-Za-z]+$/i,
                })}
              />
            </div>
            <div className="col-1">
              <input
                name="email"
                type="email"
                placeholder="Your Email*"
                {...register("email", { required: true })}
              />
            </div>
            <div className="col-1">
              <input
                name="jobTitle"
                type="text "
                placeholder="Job Title You Are Applying For"
                {...register("jobTitle", { required: true })}
              />
            </div>

            <div className="col-1 resume-col1">
              <p className="resume-label semi-para">
                Attach Resume Here (PDF, docx files only)* :
              </p>
              <input
                name="attachment"
                type="file"
                placeholder="Upload Resume"
                {...register("attachment", { required: true })}
              />
            </div>
            <div className="col-1">
              <textarea
                placeholder="Write something about your work expirience"
                rows="8"
                name="about"
                {...register("about", { required: true })}
              ></textarea>{" "}
            </div>
            <div className="col-1">
              <input
                className={`btn-filled bg-${
                  currentLanguage ? currentLanguage : "en"
                } submit-btn`}
                type="submit"
                disabled={loading ? "disabled" : ""}
              />
              {loading && (
                <div className="col loading-text">
                  <div className="loader"></div>Please Wait...
                </div>
              )}
            </div>
          </form>
        )}
        {error.length > 0 ? (
          <div className="alert alert-danger">{error}</div>
        ) : null}
      </div>
    );
  } catch (error) {
    console.log(error);
  }
};
export default Form;
